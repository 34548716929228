@import './vendors';
@import './themes/theme';
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-angular-documenteditor/styles/material.css';

.sf-icon-InsertMergeField:before {
  content: "\e701";
}

.sf-icon-FinishMerge:before {
  content: "\e700";
}

.modal-footer {
  border-top: unset;
}
.dfm-content {
  position: relative;
}
@media screen and (max-width: 960px) {
  .dfm-navigation-bar {
    min-height: 48px;
  }

  .dfm-content {
    min-height: calc(100vh - 106px);
    min-height: calc(100dvh - 106px);
  }
}
.mat-progress-bar {
  position: absolute !important;
  top: 0;
  margin-left: -32px;
  min-width: 100vw;
}
dfm-notification {
  .dfm-notification {
    right: 0 !important;
    z-index: 2000 !important;
    height: 60px !important;

    .dfm-notification-body {
      color: black !important;
      font-weight: 400 !important;
    }
  }

  .dfm-icon-wrapper {
    width: 30px !important;
    height: 30px !important;
    padding: 4px !important;
    margin-right: 8px !important;
  }

  .dfm-notification-success {
    background: #ecfdf3 !important;
    border-bottom: 3px solid #32d583 !important;

    .dfm-icon-wrapper {
      color: white !important;
      background: #12b76a !important;
    }
  }

  .dfm-notification-danger {
    background: #fee4e2 !important;
    border-bottom: 3px solid #b42318 !important;

    .dfm-icon-wrapper {
      color: white !important;
      background: #b42318 !important;
    }
  }
}

@media (max-width: 680px) {
  dfm-notification {
    .dfm-notification {
      margin: auto !important;
    }
  }
}
.e-list-group-item {
  border: unset !important;
  padding: 0 !important;

  .list-category {
    padding: 0 12px !important;

  }

  .table-category {
    &:hover {
      background-color: #eeeeee !important;
    }
  }
}

.e-list-template {
  &.child-list {
    li {
      padding: 4px 24px;
    }
  }
}

.e-list-mail-merge-template {
  li {
    padding: 4px 12px !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}
